import React from 'react';
import { Link } from 'gatsby';

function Banner() {
  return (
    <article className="pb-1.5 z-10 pt-2 text-sm font-black leading-none tracking-ultra-wide text-center uppercase bg-red-700 sticky top-0 inset-x-0 h-8 flex items-center w-full justify-center">
      <Link to="/membership">nXtLvL Forster - COMING SOON!</Link>
    </article>
  );
}

export { Banner };
